<template>
  <div id="image-modal" class="modal" style="padding: 16px">
    <div id="modal-background" class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ modalTitle }}</p>
        <button id="modal-close" class="delete" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
        <div class="container">
          <div class="columns is-multiline">
            <div class="column is-12 has-text-centered">
              <img
                v-if="isDefault"
                src="@/assets/images/simsed_logo.png"
                class="has-background-grey-lighter"
                width="240"
                height="auto"
              />
              <img
                v-else
                :src="thumbnail"
                class="has-background-grey-lighter"
                width="240"
                height="auto"
              />
            </div>
            <div class="column is-12 has-text-centered">
              <b-field class="file">
                <b-upload v-model="file" expanded>
                  <a class="button is-primary is-fullwidth">
                    <b-icon icon="upload"></b-icon>
                    <span>{{ file.name || 'Select image to upload' }}</span>
                  </a>
                </b-upload>
                <!-- <span v-if="file" class="file-name">{{ file.name }}</span> -->
              </b-field>
            </div>
          </div>
        </div>
        <!-- <progress class="progress mt-4" :value="progressBarValue" max="100">
          {{ progressBarValue }}%
        </progress> -->
      </section>

      <footer class="modal-card-foot">
        <button id="modal-cancel" class="button btn-100">Cancel</button>
        <button
          class="button btn-100 is-primary is-capitalized"
          @click="upload"
        >
          Upload
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageModal',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
    subject: { type: String, default: 'Delete' },
    showModal: { type: Boolean, default: false },
    isFixed: { type: Boolean, default: true },
    isDataUrl: { type: Boolean, default: false },
    newHeight: { type: Number, default: 240 },
  },
  data() {
    return {
      isDefault: false,
      showProgressBar: false,
      thumbnail: '',
      modalTitle: 'Upload Image',
      file: {},
      image: {
        data_url: '',
        width: 0,
        height: 0,
      },
      dataUrl: null,
    }
  },
  computed: {
    progressBarValue() {
      return this.$store.getters.getProgressBarValue
    },
  },
  watch: {
    showModal(active) {
      const modal = document.getElementById('image-modal')
      if (active) {
        modal.classList.toggle('is-active')

        const modalBackground = document.getElementById('modal-background')
        const modalClose = document.getElementById('modal-close')
        const modalCancel = document.getElementById('modal-cancel')

        ;[modalBackground, modalClose, modalCancel].forEach((el) => {
          el.addEventListener('click', () => {
            modal.classList.remove('is-active')
            this.$emit('update:showModal', false)
          })
        })

        if (this.data.id) {
          this.thumbnail = this.data.url
        } else {
          this.isDefault = true
        }
      } else {
        modal.classList.remove('is-active')
      }
    },
    file(file) {
      if (!file) return
      this.isDefault = false
      this.resize(file, this.isFixed).then((dataUrl) => {
        this.dataUrl = dataUrl
        this.thumbnail = dataUrl
        this.image.data_url = dataUrl
      })
    },
  },
  methods: {
    upload() {
      if (this.data.id) {
        this.update()
      } else {
        this.create()
      }
    },
    create() {
      if (this.isDataUrl) {
        this.$emit('create-upload', {
          subject: this.subject,
          dataUrl: this.dataUrl,
        })
      } else {
        this.$emit('create-upload', {
          subject: this.subject,
          image: this.image,
          file: this.file,
        })
      }
    },
    update() {
      if (this.isDataUrl) {
        this.$emit('update-upload', {
          subject: this.subject,
          dataUrl: this.dataUrl,
        })
      } else {
        this.$emit('update-upload', {
          subject: this.subject,
          image: this.image,
          file: this.file,
          data: this.data,
        })
      }
    },
    resize(file, fixed) {
      const reader = new FileReader()
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      return new Promise((resolve, reject) => {
        reader.onerror = (error) => {
          return reject(error)
        }
        reader.onload = () => {
          const prevImg = new Image()
          prevImg.src = reader.result
          prevImg.onload = () => {
            let width = 0
            let height = 0
            let prevWidth = prevImg.width
            let prevHeight = prevImg.height

            // console.log(prevWidth)
            // console.log(prevHeight)

            if (fixed) {
              const MAX_WIDTH = 480
              const MAX_HEIGHT = 480

              if (prevWidth > prevHeight) {
                if (prevWidth > MAX_WIDTH) {
                  prevHeight *= MAX_WIDTH / prevWidth
                  prevWidth = MAX_WIDTH
                }
              } else if (prevHeight > MAX_HEIGHT) {
                prevWidth *= MAX_HEIGHT / prevHeight
                prevHeight = MAX_HEIGHT
              }

              height = prevHeight
              width = prevWidth

              canvas.width = prevWidth
              canvas.height = prevHeight

              // console.log(width)
              // console.log(height)
            } else {
              height = this.newHeight
              const scaleFactor = height / prevHeight
              width = prevWidth * scaleFactor

              canvas.width = width
              canvas.height = height

              // console.log(width)
              // console.log(height)
            }

            this.image.width = width
            this.image.height = height

            ctx.drawImage(prevImg, 0, 0, width, height)
            const dataURL = canvas.toDataURL()
            return resolve(dataURL)
          }
        }
        reader.readAsDataURL(file)
      })
    },
  },
}
</script>
