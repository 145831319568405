<template>
  <div>
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <template v-if="admin">
            <b-tabs v-model="activeTab">
              <b-tab-item label="Basic">
                <div class="columns is-multiline">
                  <div class="column is-3 primary">Name</div>
                  <div class="column is-9 text-bg-color">
                    {{ admin.name }}
                  </div>
                  <div class="column is-3 primary">Email</div>
                  <div class="column is-9 text-bg-color">
                    {{ admin.user.email }}
                  </div>
                  <div class="column is-3 primary">Signature</div>
                  <div class="column is-9 text-bg-color">
                    <img
                      v-if="admin.signature"
                      :src="admin.signature"
                      alt="signature"
                      width="128"
                      height="auto"
                    />
                    <img
                      v-else
                      src="@/assets/images/default_signature.png"
                      alt="signature"
                      width="128"
                      height="auto"
                    />
                  </div>
                </div>
                <div class="buttons is-pulled-right">
                  <a
                    class="is-small has-text-success"
                    @click="initializeUpdate()"
                    style="font-size: 1.2em; margin-left: 16px"
                  >
                    <fa-icon icon="edit" size="lg" />
                  </a>
                  <a
                    class="is-small has-text-danger"
                    @click="initializeRemove()"
                    style="font-size: 1.2em; margin-left: 16px"
                  >
                    <fa-icon icon="trash-alt" size="lg" />
                  </a>
                </div>
              </b-tab-item>
              <b-tab-item label="Online">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <div class="field">
                      <b-switch v-model="userAuth" type="is-success"
                        >Admin Auth</b-switch
                      >
                    </div>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="Signature">
                <div class="has-text-centered">
                  <figure class="image is-256x256 is-inline-block">
                    <img v-if="admin.signature" :src="admin.signature" />
                    <img v-else src="@/assets/images/default_signature.png" />
                  </figure>
                </div>
                <br />
                <div class="has-text-centered">
                  <a
                    class="button btn-120 is-primary"
                    @click="uploadImage('signature')"
                    >Change</a
                  >
                </div>
                <ImageModal
                  :subject="imageSubject"
                  :data="
                    admin.signature
                      ? { id: admin.id, url: admin.signature }
                      : {}
                  "
                  :is-data-url="true"
                  :show-modal.sync="modal"
                  @create-upload="createUpload"
                  @update-upload="updateUpload"
                />
              </b-tab-item>
            </b-tabs>
          </template>
          <template v-else>No Admin</template>
        </div>
      </div>
    </template>
    <!-- Activate and Reset -->
    <app-modal
      id="activate-reset-admin"
      :context="context"
      @activate="activate"
      @reset="reset"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchAppUrl } from '@/assets/js/app_info'
import ImageModal from '../../components/ImageModal.vue'
import { crudNotification } from '@/assets/js/notification'

export default {
  data() {
    return {
      pageTitle: 'Admin Info',
      url: null,
      schoolId: null,
      admin: {
        user: {
          googleToken: null,
          firebaseUserId: '',
        },
      },
      activeTab: 0,
      context: null,
      modal: false,
      imageSubject: 'avatar',
    }
  },
  watch: {
    admin(data) {
      this.selectedUser = data.user
      this.userAuth = this.isActivated()
    },
    isModalOpen() {
      this.$apollo.queries.admin.refetch()
      this.userAuth = this.isActivated()
    },
  },
  components: {
    ImageModal,
  },
  methods: {
    // For image modal
    uploadImage(value) {
      this.imageSubject = value
      this.modal = true
    },
    createUpload(data) {
      if (data.subject === 'signature') {
        this.updateAdminSignature(data.dataUrl)
      }
    },
    updateUpload(data) {
      if (data.subject === 'signature') {
        this.updateAdminSignature(data.dataUrl)
      }
    },
    updateAdminSignature(signatureData) {
      // Update Signature
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateAdminSignatureQuery($id: ID!, $signature: String!) {
              updateAdminSignature(input: { id: $id, signature: $signature }) {
                admin {
                  id
                }
                errors
              }
            }
          `,
          // Parameters
          variables: {
            id: this.admin.id,
            signature: signatureData,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateAdminSignature.errors,
            'Successfully updated.'
          ).then(() => {
            this.admin.signature = signatureData
            this.modal = false
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = this.$route.params.school_id

    this.$store.commit('setSubMenus', [
      {
        name: 'Admins',
        route: `/school/${this.schoolId}/admins`,
      },
    ])

    fetchAppUrl().then((url) => {
      this.url = url
    })

    this.$apollo.addSmartQuery('admin', {
      query: gql`
        query admin($id: ID!) {
          admin(id: $id) {
            id
            name
            user {
              id
              email
              googleToken
              firebaseUserId
            }
            phone
            ownerId
            signature
          }
        }
      `,
      variables() {
        return {
          id: parseInt(this.$route.params.id),
        }
      },
      error(error) {
        this.$buefy.notification.open({
          duration: 5000,
          message: error.message,
          position: 'is-top-right',
          type: 'is-danger',
          hasIcon: true,
        })
      },
    })
    this.$apollo.queries.admin.refetch()
  },
}
</script>
